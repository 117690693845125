import {
  faFile,
  faHourglassEnd,
  faMemory,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { titlePrefix } from "../common";

export default function Faq() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${titlePrefix} - FAQ`;
  }, []);

  return (
    <div>
      <div
        className="text-xl underline  hover:cursor-pointer"
        onClick={() => navigate(-1)}
      >
        {"<"} Back
      </div>

      <div className="max-w-2xl">
        <div className="mt-4 mb-2 text-2xl font-extrabold">
          What information do the results provide?
        </div>
        <div className="text-lg leading-6">
          <p>
            The results display, for each repository: (1) if the{" "}
            <code>make</code> command succeeded, (2) if the program execution
            completed without errors, and (3) the number of correct and wrong
            commands for each query.
          </p>
          <br />
          <p>
            To select the test run being displayed, use the dropdown button on
            the top left.
          </p>
          <p>
            To check the input file used, access the{" "}
            <div className="underline inline">Input used</div> link.
          </p>
          <p>
            To check the execution log, access the{" "}
            <FontAwesomeIcon icon={faFile} /> button.
          </p>
          <p>
            If a command returns the wrong result, you can check the diff by
            clicking on the respective query. A diff will only be shown for a
            query command if the program generated an output file for that
            command. The green side represents the correct output, while the red
            side represents the (wrong) output generated by the program.
          </p>
          <p>
            Note that outputs with data must end with a newline char (
            <code>\n</code>). Empty outputs should contain a single newline char (
            <code>\n</code>).
          </p>
          <br />
          <p className="font-semibold mb-1">Query color code:</p>
          <div className="bg-green-200 dark:brightness-85 w-4 h-4 inline-block mr-2"></div>
          <p className="inline">All commands are correct</p>
          <br />
          <div className="bg-orange-200 dark:brightness-85 w-4 h-4 inline-block mr-2"></div>
          <p className="inline">Some commands are incorrect</p>
          <br />
          <div className="bg-red-200 dark:brightness-85 w-4 h-4 inline-block mr-2"></div>
          <p className="inline">All commands are incorrect</p>
          <br />
          <div className="bg-zinc-200 dark:brightness-85 w-4 h-4 inline-block mr-2"></div>
          <p className="inline">Query without output</p>
        </div>

        <hr className="my-6" />

        <div className="mt-4 mb-2 text-2xl font-extrabold">
          How are the repositories compiled?
        </div>
        <div className="text-lg leading-6">
          <p>
            We access the directory <code>trabalho-pratico</code> and execute
            the command <code>make</code>.
          </p>
        </div>

        <hr className="my-6" />

        <div className="mt-4 mb-2 text-2xl font-extrabold">
          How are the tests performed?
        </div>
        <div className="text-lg leading-6">
          <p>
            We execute the commands <code> cd trabalho-pratico </code> and{" "}
            <code>
              ./programa-principal &#60;data-folder&#62; &#60;input&#62;
            </code>
            . Ensure that non-implemented queries do not cause the program to
            crash, by, for example, ignoring the input line when reading the
            file. Otherwise, a crash on, for example, command 1, will prevent
            command 2 from being executed.
          </p>
        </div>

        <hr className="my-6" />

        <div className="mt-4 mb-2 text-2xl font-extrabold">
          What kind of tests are performed?
        </div>
        <div className="text-lg leading-6">
          <p className="mb-4">
            We currently evaluate 4 types of tests, as described in the table
            below:
          </p>
          <table>
            <thead>
              <tr className="text-white bg-slate-800 dark:bg-slate-100 dark:text-darkmode2">
                <th className="py-1 px-2 text-md font-medium">Time (GMT)</th>
                <th className="py-1 px-2 text-md font-medium">Dataset</th>
                <th className="py-1 px-2 text-md font-medium">
                  Invalid entries
                </th>
                <th className="py-1 px-2 text-md font-medium">Max Memory</th>
                <th className="py-1 px-2 text-md font-medium">Max Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">8h,16h</td>
                <td className="text-center">Small (~400MB)</td>
                <td className="text-center">No</td>
                <td className="text-center">2GB</td>
                <td className="text-center">5min</td>
              </tr>
              <tr>
                <td className="text-center">10h,18h</td>
                <td className="text-center">Small (~400MB)</td>
                <td className="text-center">Yes</td>
                <td className="text-center">2GB</td>
                <td className="text-center">5min</td>
              </tr>
	      <tr>
                <td className="text-center">12h, 20h</td>
                <td className="text-center">Big (~3GB)</td>
                <td className="text-center">No</td>
                <td className="text-center">7GB</td>
                <td className="text-center">11min</td>
              </tr>
              <tr>
                <td className="text-center">14h, 22h</td>
                <td className="text-center">Big (~3GB)</td>
                <td className="text-center">Yes</td>
                <td className="text-center">7GB</td>
                <td className="text-center">11min</td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr className="my-6" />

        <div className="mt-4 mb-2 text-2xl font-extrabold">
          What happens if the test exceeds the time limit?
        </div>
        <div className="text-lg leading-6">
          <p>
            The program will be killed and the test will be marked as{" "}
            <FontAwesomeIcon icon={faHourglassEnd} /> under "Exec".
          </p>
        </div>

        <hr className="my-6" />

        <div className="mt-4 mb-2 text-2xl font-extrabold">
          What happens if the test exceeds the memory limit?
        </div>
        <div className="text-lg leading-6">
          <p>
            The program will be killed and the test will be marked as{" "}
            <FontAwesomeIcon icon={faMemory} /> under "Exec".
          </p>
        </div>
      </div>
    </div>
  );
}
